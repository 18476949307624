import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeLink from '../../navigations/FadeLink/FadeLink';
import translate from '../../../../utils/translate';
import { graphql, useStaticQuery } from 'gatsby';
import ParagraphShort from '../../text/ParagraphShort';
import ParagraphInfo from '../../text/ParagraphInfo';
import useLng from "../../../../hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";

const BlogSectionWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: clamp(767px, 72vw, 114rem);
  margin: 0 auto 6rem;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  margin-right: ${({ mr }) => mr && '3rem'};
  padding: ${({ noPl }) => (noPl ? '3rem 0' : '3rem')};
  grid-column: ${({ wide }) => (wide ? '1 / 7' : '10 / 13')};

  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }
`;

const PostsWrapperTitle = styled.h2`
  font-size: 2rem;
  position: relative;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const RightSectionList = styled.ul`
  list-style: none;
  margin-bottom: 2rem;
`;

const RightSectionListItem = styled.li`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 2rem;
  }
`;

const RecentPostsTitle = styled.h2`
  font-size: 2rem;
  opacity: 0.7;
  text-transform: lowercase;
`;

const StyledLink = styled(FadeLink)`
  color: ${({ theme }) => theme.colors.black};
`;

const StyledTagsWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTagLink = styled(StyledLink)`
  padding: 6px 3rem;
  border: 1px solid;
  margin: 0.5rem;
  text-align: center;
  text-decoration: none;
`;

const StyledParagraphShort = styled(ParagraphShort)`
  margin-bottom: 0;
`;

const StyledDate = styled(ParagraphInfo)`
  font-size: 1.2rem;
  line-height: unset;
`;

const allPostsQuery = graphql`
  query allPostsQuery {
    posts: allDirectusPosty(
      sort: { fields: [date_time_created, created_on], order: [DESC, DESC] }
    ) {
      nodes {
        title
        slug
        created_on
        date_time_created
        translacje{
          jezyk
          title
        }
      }
    }
    categories: allDirectusPosty {
      group(field: categories) {
        fieldValue
        totalCount
      }
    }
    tags: allDirectusPosty {
      distinct(field: tags)
    }
  }
`;

const BlogWrapper = ({ children }) => {
  const postsData = useStaticQuery(allPostsQuery);
  const allPosts = postsData.posts.nodes;
  const allCategories = postsData.categories.group.filter(
    ({ fieldValue: value }) => value !== '',
  );
  const allTags = postsData.tags.distinct.filter(tag => tag !== '');
  const {t} = useLng();
  const {language} = useI18next()
  return (
    <>
      <BlogSectionWrapper>
        <Wrapper wide noPl>
          {children}
        </Wrapper>
        <Wrapper noPl>
          <PostsWrapperTitle>{t("ostatniePosty").toUpperCase()}</PostsWrapperTitle>
          <RightSectionList>
            {allPosts.map(post => {
              const en = post.translacje.find(el => el.jezyk === "en");
              const date = translate(post.date_time_created || post.created_on);
              if (language === "en"){
                if (en){
                  return (
                      <RightSectionListItem>
                        <StyledLink to={`/blog/${post.slug}`}>
                          <RecentPostsTitle>{en?.title ?? ""}</RecentPostsTitle>
                        </StyledLink>
                        <StyledDate>{date}</StyledDate>
                      </RightSectionListItem>
                  );
                }else{
                  return <></>
                }
              }
              else{

              return (
                <RightSectionListItem>
                  <StyledLink to={`/blog/${post.slug}`}>
                    <RecentPostsTitle>{post.title}</RecentPostsTitle>
                  </StyledLink>
                  <StyledDate>{date}</StyledDate>
                </RightSectionListItem>
              );
              }
            })}
          </RightSectionList>
          {/*<PostsWrapperTitle>KATEGORIE</PostsWrapperTitle>*/}
          {/*<RightSectionList>*/}
          {/*  {allCategories.map(({ fieldValue: value, totalCount }) => (*/}
          {/*    <StyledParagraphShort as="li">*/}
          {/*      <StyledLink*/}
          {/*        to={`/kategorie/${value*/}
          {/*          .trim()*/}
          {/*          .toLowerCase()*/}
          {/*          .replace(/ /g, '-')}`}*/}
          {/*      >*/}
          {/*        {value} - {totalCount}*/}
          {/*      </StyledLink>*/}
          {/*    </StyledParagraphShort>*/}
          {/*  ))}*/}
          {/*</RightSectionList>*/}
          {/*<PostsWrapperTitle>TAGI</PostsWrapperTitle>*/}
          {/*<StyledTagsWrapper>*/}
          {/*  {allTags.map(tag => (*/}
          {/*    <StyledTagLink*/}
          {/*      stylish*/}
          {/*      borderWidth="1px"*/}
          {/*      to={`/tagi/${tag*/}
          {/*        .trim()*/}
          {/*        .toLowerCase()*/}
          {/*        .replace(/ /g, '-')}`}*/}
          {/*    >*/}
          {/*      {tag}*/}
          {/*    </StyledTagLink>*/}
          {/*  ))}*/}
          {/*</StyledTagsWrapper>*/}
        </Wrapper>
      </BlogSectionWrapper>
    </>
  );
};

BlogWrapper.propTypes = {
  post: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

BlogWrapper.defaultProps = {
  post: false,
};

export default BlogWrapper;
