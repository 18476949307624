import dayjs from 'dayjs';

const months = {
  January: 'Styczeń',
  February: 'Luty',
  March: 'Marzec',
  April: 'Kwiecień',
  May: 'Maj',
  June: 'Czerwiec',
  July: 'Lipiec',
  August: 'Sierpień',
  September: 'Wrzesień',
  October: 'Październik',
  November: 'Listopad',
  December: 'Grudzień',
};

export default date => {
  const formattedDate = dayjs(date).format('MMMM DD, YYYY');
  let translatedDate = '';
  Object.keys(months).forEach(key => {
    translatedDate = formattedDate.replace(key, months[key]);
  });
  return translatedDate;
};
