import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ColorBox from '../../wraps/ColorBox/ColorBox';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.black};
  height: 100%;
  max-width: 20px;
  ${({ right }) =>
    right &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledColorBox = styled(ColorBox)`
  cursor: pointer;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${StyledFontAwesomeIcon} {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

const Arrow = ({ right }) => (
  <StyledColorBox>
    <StyledFontAwesomeIcon icon={faChevronLeft} right={right ? 1 : 0} />
  </StyledColorBox>
);

Arrow.propTypes = {
  right: PropTypes.bool,
};

Arrow.defaultProps = {
  right: false,
};

export default Arrow;
