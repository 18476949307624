import React from 'react';
import styled from 'styled-components';

export const Title = styled.h2`
  font-size: clamp(3rem, 2.4vw, 3.8rem);
  margin-bottom: 1.6rem;
`;

export const Date = styled.p`
  margin-bottom: 3rem;
  font-size: 1.8rem;
  text-transform: uppercase;
`;

export const Description = styled.p`
  line-height: 1.8;
  font-size: 1.6rem;
  margin-bottom: 3rem;
`;
