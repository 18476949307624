import React from 'react';
import Layout from 'src/layouts/Layout';
import Section from 'src/components/shared/wraps/Section/Section';
import { graphql } from 'gatsby';
import BlogSectionWrapper from 'src/components/shared/wraps/BlogSectionWrapper/BlogSectionWrapper';
import SEO from 'src/components/seo';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import translate from 'src/utils/translate';
import { Title, Date, Description } from 'src/components/Sites/Blog/Post/Post';
import HeaderSlider from 'src/components/Sites/Blog/HeaderSlider/HeaderSlider';
import BigTitle from 'src/components/shared/text/BigTitle';
import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import useLng from "src/hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";

const PostItem = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5rem;
  position: relative;

  &:not(:last-of-type) {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1rem;
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.black};
      opacity: 0.1;
    }
  }
`;

const StyledImage = styled(Image)`
  margin: 3.6rem 0;
  height: 100% !important;
  max-height: 46rem !important;
  max-width: 100% !important;
`;

const StyledReadMoreLink = styled(FadeLink)`
  margin-left: -0.8rem;
  margin-bottom: 5rem;
`;

const StyledPagination = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: center;
`;

const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: 2rem;
  text-decoration: ${({ active }) => active && 'underline'};
  opacity: 0.7;
  transition: color 0.2s;
  &:hover {
    color: ${({ theme, active }) =>
      active ? theme.colors.black : theme.colors.main};
  }
  ${({ active }) =>
    active &&
    css`
      text-decoration: underline;
      font-size: 2.2rem;
      cursor: default;
    `}
`;

const StyledBigTitle = styled(BigTitle)`
  text-align: center;
  width: 100%;
  margin-bottom: 7rem;
  a {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const BlogTemplate = ({
  data,
  location: { pathname: path },
  pageContext,
  transitionStatus,
  entry,
  exit,
}) => {
  const currentPosts = data.currentPosts.nodes;
  const promotedPosts = data.promotedPosts.nodes;
  const categoryPosts = data.categoryList.nodes;
  const tagPosts = data.tagList.nodes;
  const { numPages, currentPage, type, pageSlug } = pageContext;

  let dataList;
  let paginationLink;

  switch (type) {
    case 'kategorie':
      dataList = [...categoryPosts];
      paginationLink = '/kategorie/';
      break;
    case 'tagi':
      dataList = [...tagPosts];
      paginationLink = '/tagi-bloga/';
      break;
    default:
      dataList = [...currentPosts];
      paginationLink = '/blog/';
  }
  const { t } = useLng();
  const {language} = useI18next()
  return (
    <Layout
      path={path}
      id={`${type}-${currentPage}`}
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title="Blog" />
      <Section nonGrid site nonFlex first>
        <StyledBigTitle h1>
          <FadeLink to="/blog/">
            <NoWrap>
              Blo<RotatingLetter delay={0.4}>g</RotatingLetter>
            </NoWrap>
          </FadeLink>
        </StyledBigTitle>
        <HeaderSlider slides={promotedPosts} />
        <BlogSectionWrapper>
          {dataList.map(post => {
            const en = post.translacje.find(el => el.jezyk === "en")
            const date = translate(post.date_time_created || post.created_on);
            if (language === "en") {
              if (!en) {
                return <></>
              }
              else {
                return (
                    <PostItem key={post.slug}>
                      <div style={{width: '100%'}}>
                        <Title>{en?.title ?? ""}</Title>
                        <Date>{date}</Date>
                        <FadeLink
                            to={`/blog/${post.slug}`}
                            style={{maxWidth: '100%'}}
                        >
                          <StyledImage
                              fluid={post.preview.localFile.childImageSharp.fluid}
                              alt={en?.title ?? ""}
                          />
                        </FadeLink>
                      </div>
                      <Description>{en?.excerpts ?? ""}</Description>
                      <StyledReadMoreLink stylish to={`/blog/${post.slug}`}>
                        {t("czytajWiecej")}
                      </StyledReadMoreLink>
                    </PostItem>)
              }
            }
              else
            {
              return (
                  <PostItem key={post.slug}>
                    <div style={{width: '100%'}}>
                      <Title>{post.title}</Title>
                      <Date>{date}</Date>
                      <FadeLink
                          to={`/blog/${post.slug}`}
                          style={{maxWidth: '100%'}}
                      >
                        <StyledImage
                            fluid={post.preview.localFile.childImageSharp.fluid}
                            alt={post.title}
                        />
                      </FadeLink>
                    </div>
                    <Description>{post.excerpts}</Description>
                    <StyledReadMoreLink stylish to={`/blog/${post.slug}`}>
                      {t("czytajWiecej")}
                    </StyledReadMoreLink>
                  </PostItem>
              );
            }
          })}
          <StyledPagination>
            {Array.from({ length: numPages }, (_, i) => (
              <StyledListItem>
                <StyledFadeLink
                  to={
                    i === 0
                      ? `${paginationLink}${pageSlug}`
                      : `${paginationLink}${
                          type !== 'blog' ? `${pageSlug}/` : ''
                        }${i + 1}`
                  }
                  active={currentPage === i + 1}
                >
                  {i + 1}
                </StyledFadeLink>
              </StyledListItem>
            ))}
          </StyledPagination>
        </BlogSectionWrapper>
      </Section>
    </Layout>
  );
};

export const blogQuery = graphql`
  query blogQuery($limit: Int!, $skip: Int!, $name: String!) {
    currentPosts: allDirectusPosty(
      filter: { promoted: { eq: false } }
      sort: { fields: [date_time_created, created_on], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        created_on
        date_time_created
        excerpts
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        translacje {
          title
          excerpts
          content
          jezyk
        }
      }
    }
    promotedPosts: allDirectusPosty(
      filter: { promoted: { eq: true } }
      sort: { fields: [date_time_created, created_on], order: [DESC, DESC] }
    ) {
      nodes {
        title
        slug
        created_on
        date_time_created
        excerpts
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        translacje {
          title
          excerpts
          content
          jezyk
        }
      }
    }
    tagList: allDirectusPosty(
      filter: { tags: { eq: $name } }
      sort: { fields: [date_time_created, created_on], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        created_on
        date_time_created
        excerpts
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        translacje {
          title
          excerpts
          content
          jezyk
        }
      }
    }
    categoryList: allDirectusPosty(
      filter: { categories: { eq: $name } }
      sort: { fields: [date_time_created, created_on], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        created_on
        date_time_created
        excerpts
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        translacje {
          title
          excerpts
          content
          jezyk
        }
      }
    }
  }
`;

export default BlogTemplate;
