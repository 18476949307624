import React from 'react';
import styled from 'styled-components';
import { Title, Date, Description } from 'src/components/Sites/Blog/Post/Post';
import Image from 'gatsby-image';
import translate from 'src/utils/translate';
import {useI18next} from "gatsby-plugin-react-i18next";

const imageWrapperStyles = {
  width: '100%',
  position: 'absolute',
  maxWidth: '100%',
  maxHeight: '100%',
  height: '100%',
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const ContentBox = styled.div`
  background-color: white;
  width: 50%;
  max-height: 60%;
  z-index: 1;
  padding: 3rem 3rem 3rem 0;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const StyledImage = styled(Image)`
  @media screen and (max-width: 767px) {
    height: max(30rem, 42vw) !important;
    position: relative !important;
  }
`;

const SlideContent = (props) => {
    const { slide: { title, created_on, excerpts, preview, translacje } } = props
    const {language} = useI18next();
    const en = translacje.find(el => el.jezyk === "en")
    if (!en && language === "en") return  <></>
    if (en && language === "en"){
        return (
            <Wrapper>
                <ContentBox>
                    <Title>{en?.title ?? ""}</Title>
                    <Date>{translate(created_on)}</Date>
                    <Description>{en?.excerpts ?? ""}</Description>
                </ContentBox>
                <StyledImage
                    style={imageWrapperStyles}
                    fluid={preview.localFile.childImageSharp.fluid}
                    alt={title}
                />
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            <ContentBox>
                <Title>{title}</Title>
                <Date>{translate(created_on)}</Date>
                <Description>{excerpts}</Description>
            </ContentBox>
            <StyledImage
                style={imageWrapperStyles}
                fluid={preview.localFile.childImageSharp.fluid}
                alt={title}
            />
        </Wrapper>
    );
}
export default SlideContent;
