import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import SlideContent from 'src/components/Sites/Blog/HeaderSlider/SlideContent';
import styled from 'styled-components';
import Arrow from 'src/components/shared/navigations/Pagination/Arrow';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';

SwiperCore.use([Navigation, A11y]);

const SwiperWrapper = styled.div`
  position: relative;
`;

const StyledSwiper = styled(Swiper)`
  max-width: clamp(767px, 72vw, 114rem);
  height: clamp(40rem, 31vw, 45rem);
  margin: 0 auto 11rem;

  @media screen and (max-width: 767px) {
    height: unset;
  }
`;

const StyledPaginationButton = styled.button`
  border: none;
  border-radius: 0;
  width: 18rem;
  height: 7rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;

  @media screen and (max-width: 767px) {
    bottom: -7rem;
    left: unset;
    right: 0;
  }
`;

const HeaderSlider = ({ slides }) => {
  return (
    <SwiperWrapper>
      <StyledSwiper
        slidesPerView={1}
        navigation={{ nextEl: '#headerBlogSlider' }}
        loop
        threshold={2}
      >
        {slides.map(slide => (
          <SwiperSlide>
            <FadeLink to={`/blog/${slide.slug}`}>
              <SlideContent slide={slide} />
            </FadeLink>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <StyledPaginationButton type="button" id="headerBlogSlider">
        <Arrow right />
      </StyledPaginationButton>
    </SwiperWrapper>
  );
};

export default HeaderSlider;
